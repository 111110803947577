@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
.App {
  height: 100%;
}

h1 {
  font-family: 'DM Sans', sans-serif;
  color: white;
  font-weight: 700;
  margin: 0;
  letter-spacing: .3rem;
  font-size: 4rem;
}

h3 {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-weight: 300;
  margin: 0;
  font-size: 1.2rem;
}

.title {
  padding-left: 6rem;
  padding-top: 8rem;
  z-index: 5 !important;
  position:relative;
}

.circle {
  position: absolute;
  left: -6rem;
  top: -10rem;
  width: 33rem;
  height: 33rem;
  background: none;
  border: 7rem solid rgb(165, 84, 8);
  border-radius: 100%;
  z-index: 3;

}

.navigation {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 5rem;
  margin-bottom: 2rem;
  bottom: 0;
}

.icon {
  width: 2.5rem;
  height: 100%;
  cursor: pointer;
}

.hover {
	-webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
  transition: color 0.3s;
  width: 2.5rem;
  display: inline-block;
  position: relative;
}

.hover::before {
	position: absolute;
	top: 100%;
	left: 50%;
	color: transparent;
	content: '•';
	text-shadow: 0 0 transparent;
	font-size: 1.2em;
	-webkit-transition: text-shadow 0.3s, color 0.3s;
	-moz-transition: text-shadow 0.3s, color 0.3s;
	transition: text-shadow 0.3s, color 0.3s;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	transform: translateX(-50%);
	pointer-events: none;
}

.hover:hover::before,
.hover:focus::before {
	color: #fff;
	text-shadow: 10px 0 #fff, -10px 0 #fff;
}

.hover:hover,
.hover a:focus {
	color: #ba7700;
}

.toggle:before {
	color: #fff;
	text-shadow: 10px 0 #fff, -10px 0 #fff;
}

.circleAnimation-appear {
  opacity: 0.01;
}

.circleAnimation-appear.circleAnimation-appear-active {
  transition: all 500ms linear;
  opacity: 1;
  transition-delay: 500ms;
}

.titleAnimation-appear {
  opacity: 0;
}

.titleAnimation-appear.titleAnimation-appear-active {
  opacity: 1;
  transition: all 500ms linear;
}

.iconAnimation-appear {
  opacity: 0;
}

.iconAnimation-appear.iconAnimation-appear-active {
  opacity: 1;
  transition: all 500ms linear;
}

.imageAnimation-appear {
  opacity: 0;
}

.imageAnimation-appear.imageAnimation-appear-active {
  opacity: 1;
  transition: all 1000ms linear;
  transition-delay: 1000ms;
}

.character {
  width: 100%;
  height: 100%;
}

.chestnut {
  background-color: #3f0f0c;
  transition: background-color 1000ms linear;

}

.mulberry {
  background-color: #ba4268;
  transition: background-color 1000ms linear;

}

.maple {
  background-color: #a1252a;
  transition: background-color 1000ms linear;

}

.fir {
  background-color:#137c39;
  transition: background-color 1000ms linear;

}

.fig {
  background-color: #1d3550;
  transition: background-color 1000ms linear;

}

.gingko {
  background-color: #859b0d ;
  transition: background-color 1000ms linear;

}

.header {
  padding-left: 2rem;
  padding-top: 2rem;
}

h2 {
  font-family: 'DM Sans', sans-serif;
  color: white;
  font-weight: lighter;
  margin: 0;
  letter-spacing: .2rem;
  font-size: 2.5rem;
}

.backgroundAnimation-appear {
  background-color: #863e1e;
}

.backgroundAnimation-appear.backgroundAnimation-appear-active {

  transition: background-color 1000ms linear;
}

.information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.characterInfo {
  width: 45%;
  text-align: center;
}

.characterInfo h3 {
  font-size: 1rem;
}

.treeInfo {
  width: 45%;

  text-align: center;
}

.tree {
  width: auto;
  max-height: 30rem;
}

.characterPicture {
  max-height: 25rem;
  width: auto;
  display: block;
  align-self: center;
  margin-bottom: 1rem;
}

.characterFormat {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}